import React, { useEffect, useState } from 'react';
import SEO from '../components/seo';
import Payment from '../containers/Payment';
import { ResetCSS } from 'common/src/assets/css/style';
import { useAlert } from 'react-alert';
import axios from 'axios';
import queryString from 'query-string';
import displayErrors from '../utils';

const PaymentPage = () => {
  const alert = useAlert();
  const [titleText, setTitleText] = useState('Processing...');
  const [captionText, setCaptionText] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var email, subscriptionId;
    if (window) {
      email = queryString.parse(window.location.search).email;
      subscriptionId = queryString.parse(window.location.search).subscriptionId;
    }

    axios
      .post(`${process.env.GATSBY_API_URL}/completePurchase`, {
        email,
        subscriptionId,
      })
      .then((res) => {
        setLoading(false);
        setTitleText('Payment Complete!');
        setCaptionText('Check your email for confirmation.');
        alert.show(
          <div style={{ textTransform: 'initial' }}>{res.data.message}</div>,
          { type: 'success' }
        );
      })
      .catch((err) => {
        setLoading(false);
        setTitleText('Payment Failed!');
        displayErrors(err, alert);
      });
  }, []);

  return (
    <>
      <SEO title="Payment" />
      <ResetCSS />
      <Payment
        titleText={titleText}
        captionText={captionText}
        isLoading={loading}
      ></Payment>
    </>
  );
};

export default PaymentPage;
